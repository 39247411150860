<template>
 
 <div id="content" class="wp-content" v-html="post.content.rendered">
      
  </div>
</template>

<script>
import {BASE_URL} from '../config.js';
export default {
    data() {
        return {
            post: []
        }
    },
    mounted() {
       
        var that = this;
         fetch(BASE_URL+'/wp-json/wp/v2/posts/19')
      .then((r) => r.json())
      .then(function(res) {
          
        that.post = res;
      } );
    },
}
</script>

<style scoped>
.wp-content {
    max-width: 60vw;
  margin: auto;
    margin-top: 5em;
}
</style>